@media only screen and (max-width: 450px) {
    #root {
        --font_size_adjust: var(--font-size_s);
        --font_h1_size_adjust: var(--font-size_l);
        --min_width_adjust: 230px;
    }

    .card_image {
        flex-direction: column;
        justify-content: center;
    }

    .card_image .content_holder .title h1 {
        text-align: center;
    }

    .card_image .content_holder p {
        text-align: center;
    }

    .card_image .content_footer {
        justify-content: center;
    }
}

.card_image {
    display: flex;
    background-color: var(--color_background);
    border-radius: 10px;
    padding: var(--margin);
    align-items: center;
    text-align: left;
    justify-items: center;
    margin: var(--margin);
    box-shadow: var(--box-shadow);
    width: 40%;
    min-width: var(--min_width_adjust, 400px);
    font-size: var(--font-size_s);
}

.card_image h1 {
    font-size: var(--font-size_m);
}

.card_image .content_holder {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.card_image .image_holder_square {
    margin: 10px;
}

.card_image .content_holder .content_footer {
    display: flex;
    font-weight: 700;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 10px;
    text-align: center;
    align-self: flex-end;
}

.card_image .content_holder .content_footer .content{
    display: flex;
    align-self: flex-end;
}