@keyframes slide_in{
    from{
        transform: translateX(100%);
    }
    to{
        transform: translateX(0);
    }
}

@keyframes slide_out{
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(-100%);
    }
}