.card_title_text .content_holder {
   width:80%;
}

.card_title_text .content_holder .content {
    display: flex;
    justify-content: space-between;
    width:100%;
    font-size: var(--font-size_s);
}

#creme_button.active{
    background-color: #ffffff !important;
    color: var(--color_third) !important;
    font-weight: bold;
    box-shadow: 1px 1px 10px var(--color_third);
}

#creme_button.inactive{
    background-color: var(--color_third) !important;
    filter:opacity(0.7);
    color: white !important;    
}

#tomate_button.active{
    background-color: var(--color_second) !important;
    color: white !important;
    font-weight: bold;
    box-shadow: 1px 1px 10px var(--color_second);
}

#tomate_button.inactive{
    background-color: var(--color_third) !important;
    filter:opacity(0.7);
    color: white !important;    
}

.label_holder p {
    margin: 20px 20px 0 20px;
}