.image_holder_square{
  display: flex;
  margin-right: 10px;
  margin-bottom:10px;
  width: max(140px,min(15vw, 250px));
  height:auto;
  aspect-ratio: 1;
}

.image_holder_square img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}