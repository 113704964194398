.leaflet-container {
    background-color: var(--color_background);
    color: var(--color_background);
    width: 100%;
    height: 30vh;
    margin-top: 20px;
    margin-bottom: 20px;
}

.leaflet-bar{
    background-color: var(--color_background);
}
