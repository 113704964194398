@media only screen and (min-width:1000px){
    header {
        flex-direction: row;
    }
    header nav{
        justify-content: flex-end;
    }
}

@media only screen and (max-width:1000px){
    header{
        flex-direction: column;
    }

    header nav{
        justify-content: center;
    }
}

@media only screen and (max-width:610px){
    header nav{
        --_nav_flex_direction : column;
        align-items: center;
    }
}

header {
    padding: var(--margin) 0 var(--margin) 0;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

header svg {
    justify-self: start;
    height: var(--image-size_l);
    width: auto;
}

header nav{
    font-size: var(--font-size_xl);
    color: var(--color_third);
    padding: var(--margin);
    text-align: right;
    display: flex;
    
    flex-wrap: wrap;
    flex-direction: var(--_nav_flex_direction,row);
}

header a{
    white-space: nowrap;
    max-width: fit-content;
    color: #F5962A;
    text-decoration: none;
    margin-right: var(--margin);
    margin-left: var(--margin);
    font-weight: bolder;
    text-decoration: underline;
}

header a:hover{
    color: var(--color_second);
    text-decoration: none;
}