.dropdown_menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    margin: var(--margin) 0 var(--margin) 0;
}

.dropdown_menu .h1_holder{
    background-color: transparent;
}

.dropdown_menu .h1_holder h1{
    color: var(--color_third);
    background-color: transparent;
    position:relative;
}

.dropdown_menu button{
    border: 0;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 5px 5px;
    background-color: var(--color_active_button);
}

.dropdown_menu button:hover{
    cursor:pointer;
    background-color: var(--color_);
    background-color: var(--color_interaction_button);
}

.dropdown_menu button svg{
    height:var(--image-size_s);
   width: auto;
   scale: 1.5;
   color:white;
}

.dropdown_menu .dropdown_inactive{
    display:none;
}
