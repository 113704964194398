@media only screen and
	(max-width:350px){
	#root{
        --filter_button_adjust: wrap;
	}
}

.label .label_buttons{
    display: flex;
    flex-wrap: var(--filter_button_adjust,nowrap);
    justify-content: center;
}

.label{
    display:flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.label .label_buttons button{ 
    margin: var(--margin);
    padding: var(--margin);
    border-radius: 5px;
    border: none;
    font-size: var(--font-size_m);
    color: var(--color_forth);
}

.label .label_buttons button:hover{ 
    filter: brightness(0.8);
    cursor: pointer;
}

.label p {
    margin: 0;
    font-size: var(--font-size_l);
    font-weight: bold;
    color: var(--color_forth);
    text-align: center;
}