@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-50px);
	}
	100% {
		transform: translatey(0px);
	}
}