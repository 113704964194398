.carousel{
    width: 100%;
    height: 65vh;
    position:relative;
    overflow: hidden;
    background-color: var(--color_background);
    box-shadow: 1px 1px 10px var(--color_forth);
}

.carousel_image_holder{
    width: 100%;
    height:97%;
    position:absolute;
}

.carousel_image_holder img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carousel_image_off{
    display:none;
    transform: translateX(100%);
}

.carousel_image_transition_out{
    animation-name:slide_out;
    transform: translateX(-100%);
    animation-duration: var(--transition_time);
    animation-timing-function: ease-in-out;
}

.carousel_image_on{
    animation-name:slide_in;
    transform: translateX(0);
    animation-duration: var(--transition_time);
    animation-timing-function: ease-in-out;
}