.card_title_passer_commande .h1_holder {
  background-color: var(--color_second);
}

.card_title_passer_commande .descritpion_holder{
  display: flex;
  margin: 0 var(--margin) 0 var(--margin);
  justify-content: space-evenly;
}

.card_title_passer_commande .descritpion_holder .title_holder {
  background-color: var(--color_first);
  margin-bottom: 10px;
  height: auto;
  min-width: 120px;
  color: var(--color_text_second);
  border-radius: 5px;
}

.card_title_passer_commande .descritpion_holder .opening .title_holder h1 {
  font-size: var(--font-size_l);
  margin-left: var(--margin);
  margin-right: var(--margin);

}

.card_title_passer_commande .phone{
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: var(--color_second);
  margin: var(--margin);
}

.card_title_passer_commande .phone a{
  
  font-size: var(--font-size_l);
  margin: 0;
  color: inherit;
  text-decoration: none;
  font-weight: bold; 
}

.card_title_passer_commande .phone a:hover{
  cursor:pointer;
  color: inherit;
  text-decoration: none;
}

.card_title_passer_commande .phone svg {
  height:var(--image-size_s);
  width: auto;
}

.card_title_passer_commande .descritpion_holder .opening {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-bottom: 20px;
}

.descritpion_holder .opening .opening_holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.descritpion_holder .opening .opening_holder p {
  font-weight: normal;
  margin: 0;
  font-size: var(--font-size_m);
  text-align: center;
}

.footer div {
  text-align: right;
}

.footer p {
  margin: 0;
  text-align: left;
}