#root{
    --color_first: #8ACB88;
    --color_second: #A5243D;
    --color_third: #878686;
    --color_forth: #B19787;
    --color_fifth: #8ACB88;
    --color_background:#FFF7F3;
    --color_text_second: #FFFFFF;

    --color_active_button:rgb(200, 200, 200);
    --color_interaction_button:rgb(150, 150, 150);
}