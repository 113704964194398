@media only screen and (min-width:1300px) {
    #root {
        --font-size_xl: 35px;
        --font-size_l: 28px;
        --font-size_m: 25px;
        --font-size_s: 18px;

        --image_size_xxl: 800px;
        --image-size_l: 250px;
        --image-size_s: 40px;
    }
}

@media only screen and (min-width:1100px) and (max-width:1300px) {
    #root {
        --font-size_xl: 35px;
        --font-size_l: 28px;
        --font-size_m: 25px;
        --font-size_s: 18px;
        
        --image_size_xxl: 800px;
        --image-size_l: 250px;
        --image-size_s: 40px;
    }
}

@media only screen and (min-width:850px) and (max-width:1100px) {
    #root {
        --font-size_xl: 35px;
        --font-size_l: 25px;
        --font-size_m: 20px;
        --font-size_s: 15px;
        
        --image_size_xxl: 800px;
        --image-size_l: 250px;
        --image-size_s: 40px;
    }
}

@media only screen and (min-width:500px) and (max-width:850px) {
    #root {
        --font-size_xl: 35px;
        --font-size_l: 25px;
        --font-size_m: 20px;
        --font-size_s: 15px;

        --image_size_xxl: 800px;
        --image-size_l: 250px;
        --image-size_s: 40px;
    }
}

@media only screen and (max-width:500px) {
    #root {
        --font-size_xl: 25px;
        --font-size_l: 25px;
        --font-size_m: 20px;
        --font-size_s: 15px;

        --image_size_xxl: 800px;
        --image-size_l: 150px;
        --image-size_s: 40px;
    }
}